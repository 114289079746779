<!-- eslint-disable -->
<template>
  <v-app id="home">
    <home-header />
    <!-- <home-bar /> -->
    <b-modal v-model="showDriverModal" @hidden="handleModalHide" hide-footer size="lg">
    <template #modal-title>
      Ajouter un chauffeur
    </template>
    <div style="width:100%;text-align:center"><span style="color:red">{{newDriverErrorMessage}}</span></div>
    <div style="width:100%;text-align:center"><span style="color:green">{{newDriverSuccessMessage}}</span></div>
    <v-container class="py-0">
        <b-form @submit.prevent="addNewDriver">
        <v-row>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newDriver.email" label="Email" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newDriver.password" label="Mot de passe" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newDriver.name" label="Nom" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newDriver.phone" placeholder="phone1/phone2" label="N° Téléphone" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <label>Wilaya</label>
          <b-form-select required v-model="selectedWilaya" @change="newDriver.wilaya = selectedWilaya.name">
            <option v-for="wilaya in wilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="6">
          <label>Commune</label>
          <b-form-select required v-model="selectedCommunue" @change="newDriver.communue = selectedCommunue">
            <option v-for="communue in selectedWilaya.communues" :key="communue.name" :value="communue.name">{{communue.name}}</option>
          </b-form-select>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="newDriver.address" label="Adresse" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required v-model="newDriver.code" placeholder="XXXXX-XXX-XX" label="Matricule" class="purple-input"/>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <label>Type de Véhicule</label>
          <b-form-select v-model="newDriver.selectedVehicule" required>
            <option v-for="(vehicule,index) in vehicules" :key="index" :value="vehicule">{{vehicule}}</option>
          </b-form-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <label>Type de Service (à rejoindre)</label>
          <b-form-select v-model="newDriver.selectedService" required>
            <option v-for="(vehicule,index) in ['Service SOS (Auto Dépannage)','Service Logistique', 'Service Express', 'Logistique/Express']" :key="index" :value="vehicule">{{vehicule}}</option>
          </b-form-select>

        </v-col>
        </v-row>
        <v-row>
        <v-col cols="12" md="6" class="text-left">
          <v-btn @click="addDocs" style="background-color:#efefef;color:black;padding:5px;border-radius:5px">
            Ajouter un document
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" class="text-right">
          <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
            Ajouter
          </v-btn>
        </v-col>
        </v-row>
        <v-row v-for="(doc,i) in docs" :key="doc.id">
          <v-col cols="12" md="6">
            <v-text-field required v-model="doc.name" label="Nom de document" class="purple-input"/>
          </v-col>
          <v-col cols="12" md="5">
            <!-- <input type="file" id="myfile" name="myfile"><br><br> -->
            <input required style="margin-top:15px" type="file" id="file" ref="file" v-on:change="loadFileName(i)" accept=".pdf, .jpg, .jpeg, .png">
          </v-col>
          <v-col cols="12" md="1">
            <v-icon @click="removeDoc(i)">mdi-close</v-icon>
          </v-col>
        </v-row>
        </b-form>
    </v-container>
  </b-modal>
    <div class="home-slider">
      <div class="home-slider-title">
        <h1>Le 1er</h1> 
        <h1 style="background-color:#ffe209">de la Logistique</h1>
        <h1 style="background-color:#ffe209">en Algérie</h1>
      </div>
      <div class="imgs" :style="'width:'+widthScreen*2+'px;'">
        <!-- <img :key="image" v-for="image in sliderImages" :src="require(image)"/> -->
        <img :src="require('../../assets/Picture0.png')" :style="'width:'+widthScreen+'px'"/>
        <img :src="require('../../assets/Picture1.png')" :style="'width:'+widthScreen+'px'"/>
        <!-- <img :src="require('../../assets/Picture5.jpg')" :style="'width:'+widthScreen+'px'"/>
        <img :src="require('../../assets/Picture6.jpg')" :style="'width:'+widthScreen+'px'"/> -->
      </div>
      <!-- <div @click="toggleSlider('left')" class="slider-button cursor-pointer" style="float:left;"> <i class="arrow arrow-right"></i> </div>
      <div @click="toggleSlider('right')" class="slider-button cursor-pointer" style="float:right;"> <i class="arrow arrow-left"></i> </div> -->
      <div @click="toggleSlider('left')" class="slider-button slider-button-left cursor-pointer slider-button-1"></div>
      <div @click="toggleSlider('right')" class="slider-button slider-button-right cursor-pointer slider-button-1"></div>
    </div>
    <div class="home-tiles home-tiles-1">
      <a class="read-container" href="/about-us" >
        <div style="padding:10px 10px">
          <p>Web Marchand</p>
          <p>E-Commerçants</p>
          <p>Vente En Ligne</p>
          <p>Artisans</p>
          <p>Particuliers</p>
        </div>
      </a>
      <a class="read-container" href="/about-us" >
        <div style="padding:10px 10px">
          <p>Nous Stockons</p>
          <p>Votre Marchendise</p>
          <p>Pour L'acheminer</p>
          <p>Vers Vos Client</p>
          <p>Suivez-Le Et Gérez-Le</p>
        </div>
      </a>
      <a class="read-container" href="/about-us" >
        <div style="padding:40px 10px">
          <p>Tracking</p>
          <p>Suivi Des Colis</p>
          <p>Et Des Livraison</p>
        </div>
      </a>
    </div>
    <div class="home-slider" style="margin-top:-40px;margin-bottom:100px">
      <img :src="require('../../assets/driver_background.png')"/>
      <div style="height:500px;width:100%;background-color:transparent;position:absolute;display:flex;flex-wrap:wrap;justify-content:center">
        <div class="driver-container hidden-sm-and-down">
        </div>
        <div class="driver-container">
          <div class="driver-container-main">
            <div class="driver-container-content">
              <h1>TLM Chauffeurs</h1>
              <p class="shown-sm-and-down">Vous êtes un chauffeur? Possédez-vous d'un véhicule ou un camion pouvant contenir de la marchandise? Si oui, rejoignez notre famille à TLM, travaillez avec nous avec des prix intéressants et des dernières technologies de transport et augmentez encore plus vos revenus mensuels. Abonnez-vous maintenant et en savoir plus sur nous.</p>
              <p class="hidden-sm-and-down">Vous êtes un chauffeur? Possédez-vous d'un véhicule ou un camion pouvant contenir de la marchandise? Si oui, rejoignez notre famille à TLM, travaillez avec nous avec des prix intéressants et des dernières technologies de transport et augmentez encore plus vos revenus mensuels. Abonnez-vous maintenant et en savoir plus sur nous.</p>
            </div>
            <div class="driver-container-button">
              <div @click="showDriverModal=true"><p>Inscription</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-def">
      <h1 style="color:#0b2963;font-weight:900;">QUI SOMME NOUS?</h1>
      <v-row>
        <v-col cols="12" md="4">
          <h1 style="color:#0b2963;font-weight:400">Etablissement</h1>
          <p style="color:#333333">Acteurs incontournables depuis 2011 basée à Annaba, nous intervenons dans les secteurs du transport et de la logistique marchendises.</p>
          <p style="color:#333333">Notre mission est d'industrialiser la logistique et de la mettre au service de l'economie.</p>
          <p style="color:#333333">Notre entreprise exploite une flotte de plus de 30 camions et 20 fourgons.</p>
          <p style="color:#333333">En 2021, nous avons transporté et groupé, sur l'ensemble des 30 Wilayas, plus de 10 mille tonnes de marchendises divers.</p>
        </v-col>
        <v-col class="hidden-sm-and-down" cols="12" md="1">
        </v-col>
        <v-col class="hidden-sm-and-down" cols="12" md="5">
          <img :src="require('../../assets/cargo_truck2.png')"/>
        </v-col>
      </v-row>
    </div>
    <div class="home-tiles home-tiles-2">
      <a class="mission-container" href="/about-us" >
        <div class="mission-container-image-container"><div class="mission-container-image mission-container-image-first"></div></div>
        <div class="mission-container-content">
          <h3 style="color:#0b2963;">Mission</h3>
          <p style="margin-top:-5px">Nous mettons notre expertise à votre service pour vous accompagner dans la réalisation de vos objectifs et ceux de vos clients. A travers une variété d'offres logistiques et d'intégrations dans vos procès, l'externalisation devient possible et s'impose pour votre développement, l'optimisation de vos coûts, la qualité de service, la maitrise des délais.</p>
        </div>
        <v-icon style="font-size:40px;color:#0b2963;width:100%;justify-content:center">mdi-plus-circle</v-icon>
        <div class="menu-item-bar"></div>
      </a>
      <a class="mission-container" href="/about-us" >
        <div class="mission-container-image-container"><div class="mission-container-image mission-container-image-second"></div></div>
        <div class="mission-container-content">
          <h3 style="color:#0b2963;">Service groupage</h3>
          <p style="margin-top:-5px">A l’instar des autres pays, l’organisation du transport dans notre pays doit s’ouvrir sur le groupage qui est aujourd’hui systématique. TLM logistique travaille depuis des années pour développer ce modèle qui nécessite un investissement colossal, des systèmes d’informations et des moyens en ressources humaines très importants. Sur la base d’un réseau traditionnel et des acteurs artisanaux qui activent depuis des années pour relier l’ensemble des régions du pays en optimisant les navettes et les couts de transport, nous nous attelons pour développer ce marché et tisser une toile de façon pragmatique et professionnelle.</p>
        </div>
        <v-icon style="font-size:40px;color:#0b2963;width:100%;justify-content:center">mdi-plus-circle</v-icon>
        <div class="menu-item-bar"></div>
      </a>
      <a class="mission-container" href="/about-us" >
        <div class="mission-container-image-container"><div class="mission-container-image mission-container-image-third"></div></div>
        <div class="mission-container-content">
          <h3 style="color:#0b2963;">Stock et Manutention</h3>
          <p style="margin-top:-5px">VOUS CHERCHEZ UN PARTENAIRE FIABLE POUR VOTRE LOGISTIQUE DE STOCKAGE OU UN PRESTATAIRE POUR VOS OPERATIONS DE MANUTENTION? TLM vous propose une solution complète pour votre logistique de stockage, d’approvisionnement et/ou de distribution. Optimisez vous niveaux de services, externalisez les processus logistiques et concentrez vous sur votre cœur de métier. Notre expérience et savoir faire nous permettent de vous proposer des solutions de transport et logistiques intégrées en fonction de votre secteur d’activité.</p>
        </div>
        <v-icon style="font-size:40px;color:#0b2963;width:100%;justify-content:center">mdi-plus-circle</v-icon>
        <div class="menu-item-bar"></div>
      </a>
      <a class="mission-container" href="/about-us" >
        <div class="mission-container-image-container"><div class="mission-container-image mission-container-image-fourth"></div></div>
        <div class="mission-container-content">
          <h3 style="color:#0b2963;">Etablissement</h3>
          <p style="margin-top:-5px">Acteurs incontournables depuis 2011 basée a annaba, nous intervenons dans les secteurs du transport et de la logistique marchandises. Notre mission est d’industrialiser la logistique et de la mettre au service de l’économie. Notre entreprise exploite une flotte de plus de 30 camions et 20 fourgons. En 2021, nous avons transporté et groupé, sur l’ensemble des 30 Wilayas, plus de 10 mille tonnes de marchandises divers.</p>
        </div>
        <v-icon style="font-size:40px;color:#0b2963;width:100%;justify-content:center">mdi-plus-circle</v-icon>
        <div class="menu-item-bar"></div>
      </a>
    </div>
    <div class="hidden-sm-and-down" style="width:100%;justify-content:center;text-align:center;display:flex;margin-left:20px;margin-top:-250px;margin-bottom:50px">
      <div style="width:90%;height:230px;background-color:#f6f6f6;border-radius:20px"></div>
    </div>
    <div class="home-def">
      <h1 style="color:#0b2963;font-weight:900;">Nos Services</h1>
    </div>
    <div class="home-tiles home-tiles-3 shown-sm-and-down">
      <a class="service-container-middle" href="/about-us" >
        <div style="padding:0px 0px">
          <!-- <v-icon style="font-size:70px;color:#ffe209;">mdi-truck-outline</v-icon> -->
          <img :src="require('../../assets/gnp1.png')"/>
          <img :src="require('../../assets/gnpy1.png')"/>
          <h2 style="color:#ffe209;">TLM Logistique</h2>
          <p style="color:white;font-size:17px;line-height:2">Crée en 2011 a Annaba, nous intervenons dans le secteurs du transport et de la logistique marchandises. Notre mission est d’industrialiser la logistique. Notre entreprise exploite une flotte de plus de 30 camions et 20 fourgons. En 2021 nous avons transporté et groupé sur l’ensemble des 30 Wilayas plus de 10 mille tonnes de marchandises divers.</p>
        </div>
      </a>
      <a class="service-container-middle" href="/about-us" >
        <div style="padding:0px 0px">
          <!-- <v-icon style="font-size:70px;color:#ffe209;">mdi-truck-outline</v-icon> -->
          <img :src="require('../../assets/gnp1.png')"/>
          <img :src="require('../../assets/gnpy2.png')"/>
          <h2 style="color:#ffe209;">TLM Express</h2>
          <p style="color:white;font-size:17px;line-height:2">TLM express une entreprise algérienne de livraison courriers et de colis pour les particuliers et les entreprises basée a Annaba depuis 2020 disponible dans plusieurs villes en Algérie avec un délais de livraison très rapide et une équipe professionnelle et  discipliné et répand a touts vos besoins .</p>
        </div>
      </a>
      <a class="service-container-middle" href="tel:0655516703">
        <div style="padding:0px 0px">
          <!-- <v-icon style="font-size:70px;color:#ffe209;">mdi-truck-outline</v-icon> -->
          <img :src="require('../../assets/gnp3.png')"/>
          <img :src="require('../../assets/gnpy3.png')"/>
          <h2 style="color:#ffe209;">TLM SOS</h2>
          <p style="color:white;font-size:17px;line-height:2">C’est un service rapide de dépannage pour véhicules et camions en Algérie qui offre une disponibilité rapide d’un camion dépanneur partout en algerie et en touts moment avec des prix raisonnables et fix.<br><span style="color:red;font-weight:bold;font-size:22px">Cliquer pour Appeler</span></p>
        </div>
      </a>
    </div>
    <div class="home-tiles home-tiles-3 hidden-sm-and-down">
      <a class="service-container" href="/about-us" >
        <div style="padding:0px 0px">
          <!-- <v-icon style="font-size:70px;color:#0b2963;">mdi-truck-outline</v-icon> -->
          <img :src="require('../../assets/gnp1.png')"/>
          <img :src="require('../../assets/gnpy1.png')"/>
          <h2 style="color:#0b2963;margin-top:10px">TLM Logistique</h2>
          <p style="color:black;font-size:17px;line-height:2">Crée en 2011 a Annaba, nous intervenons dans le secteurs du transport et de la logistique marchandises. Notre mission est d’industrialiser la logistique. Notre entreprise exploite une flotte de plus de 30 camions et 20 fourgons. En 2021 nous avons transporté et groupé sur l’ensemble des 30 Wilayas plus de 10 mille tonnes de marchandises divers.</p>
        </div>
      </a>
      <a class="service-container-middle" href="/about-us" >
        <div style="padding:0px 0px">
          <!-- <v-icon style="font-size:70px;color:#ffe209;">mdi-truck-outline</v-icon> -->
          <img :src="require('../../assets/gnp1.png')"/>
          <img :src="require('../../assets/gnpy2.png')"/>
          <h2 style="color:#ffe209;margin-top:10px">TLM Express</h2>
          <p style="color:white;font-size:17px;line-height:2">TLM express une entreprise algérienne de livraison courriers et de colis pour les particuliers et les entreprises basée a Annaba depuis 2020 disponible dans plusieurs villes en Algérie avec un délais de livraison très rapide et une équipe professionnelle et  discipliné et répand a touts vos besoins .</p>
        </div>
      </a>
      <a class="service-container" href="tel:0655516703">
        <div style="padding:0px 0px">
          <!-- <v-icon style="font-size:70px;color:#0b2963;">mdi-truck-outline</v-icon> -->
          <img :src="require('../../assets/gnp3.png')"/>
          <img :src="require('../../assets/gnpy3.png')"/>
          <h2 style="color:#0b2963;margin-top:10px">TLM SOS</h2>
          <p style="color:black;font-size:17px;line-height:2">C’est un service rapide de dépannage pour véhicules et camions en Algérie qui offre une disponibilité rapide d’un camion dépanneur partout en algerie et en touts moment avec des prix raisonnables et fix.<br><span style="color:red;font-weight:bold;font-size:22px">Cliquer pour Appeler</span></p>
        </div>
      </a>
    </div>
    <div class="home-def">
      <h1 style="color:#0b2963;font-weight:900;">Nouveau</h1>
    </div>
    <div>
      <div class="home-tiles home-tiles-4" style="margin-left:25px;">
        <a class="news-container" href="/about-us" >
          <div class="news-container-image-container">
            <div v-if="allNews[0].isContain == true" class="news-container-image news-container-image-first" v-bind:style="{ 'background-image': 'url(' + allNews[0].image + ')', 'background-size':'contain' }"></div>
            <div v-else class="news-container-image news-container-image-first" v-bind:style="{ 'background-image': 'url(' + allNews[0].image + ')' }"></div>
          </div>
          <div class="news-container-content">
            <h3 style="color:#0b2963;">{{allNews[0].title}}</h3>
            <p style="margin-top:-5px">{{allNews[0].content}}</p>
          </div>
          <!-- <v-icon style="font-size:40px;color:#0b2963;width:100%;justify-content:center">mdi-plus-circle</v-icon> -->
          <div class="menu-item-bar" style="margin-top:50px"></div>
        </a>
        <a class="news-container hidden-sm-and-down"  href="/about-us" >
          <div class="news-container-image-container">
            <div v-if="allNews[1].isContain == true" class="news-container-image news-container-image-first" :style="'background-image: url('+allNews[1].image+');background-size:contain'"></div>
            <div v-else class="news-container-image news-container-image-first" :style="'background-image: url('+allNews[1].image+');'"></div>
          </div>
          <div class="news-container-content">
            <h3 style="color:#0b2963;">{{allNews[1].title}}</h3>
            <p style="margin-top:-5px">{{allNews[1].content}}</p>
          </div>
          <!-- <v-icon style="font-size:40px;color:#0b2963;width:100%;justify-content:center">mdi-plus-circle</v-icon> -->
          <div class="menu-item-bar" style="margin-top:50px"></div>
        </a>
      </div>
      <div @click="toggleNews('left')" class="hidden-sm-and-down slider-button slider-button-left cursor-pointer" style="margin:-400px 150px;"></div>
      <div @click="toggleNews('right')" class="hidden-sm-and-down slider-button slider-button-right cursor-pointer" style="margin:-400px 100px;"></div>
      <div @click="toggleNews('left')" class="shown-sm-and-down slider-button slider-button-left cursor-pointer" style="margin:-400px 0px !important;"></div>
      <div @click="toggleNews('right')" class="shown-sm-and-down slider-button slider-button-right cursor-pointer" style="margin:-400px 0px !important;"></div>
    </div>
    <div class="tlm-numbers-container">
      <div class="tlm-numbers-container-title-title"><h1>TLM En Chiffre</h1>
        <v-row>
          <v-col cols="12" md="4"><div class="tlm-numbers-container-title"><h1>30</h1><h2>Wilaya</h2></div></v-col>
          <v-col cols="12" md="4"><div class="tlm-numbers-container-title"><h1>25</h1><h2>Chauffeur</h2></div></v-col>
          <v-col cols="12" md="4"><div class="tlm-numbers-container-title"><h1>10k</h1><h2>Utilisateur</h2></div></v-col>
        </v-row>
      </div>
    </div>
    <div class="home-def home-def-1">
      <v-row>
        <v-col cols="12" md="5">
          <h1 class="review-title">TLM Express</h1>
          <p class="review-content">Téléchargez notre application TLM Express pour une meilleure expérience et rejoignez-nous pour rendre le transport formidable !</p>
          <h2 style="color:#0b2963;font-weight:700;margin-top:30px">Télécharger application</h2>
          <a href="https://play.google.com/store/apps/details?id=tlm.express.tlm_express" target="_blank"><img :src="require('../../assets/gplay.png')"/></a>
          <img :src="require('../../assets/appstore.png')"/>
        </v-col>
        <v-col class="tlm-spacer hidden-sm-and-down" cols="12" md="1">
        </v-col>
        <v-col cols="12" md="5">
          <img class="review-man" :src="require('../../assets/review_man.png')"/>
        </v-col>
      </v-row>
    </div>
    <div class="hidden-sm-and-down our-partners">
      <div class="tlm-numbers-container-title-title"><h1>Nos Partenaires</h1>
      </div>
      <div style="margin-top:40px;">
        <div @click="togglePartners('left')" class="slider-button slider-button-left cursor-pointer" style="margin-top:20px"></div>
        <div @click="togglePartners('right')" class="slider-button slider-button-right cursor-pointer" style="margin-top:20px"></div>
        <v-row>
          <v-col cols="12" md="2">
            <img v-bind:src="allPartners[0]" style="max-width:150px"  height="70px"/>
          </v-col>
          <v-col cols="12" md="2">
            <img v-bind:src="allPartners[1]" style="max-width:150px" height="70px"/>
          </v-col>
          <v-col cols="12" md="2">
            <img v-bind:src="allPartners[2]" style="max-width:150px" height="70px"/>
          </v-col>
          <v-col cols="12" md="2">
            <img v-bind:src="allPartners[3]" style="max-width:150px" height="70px"/>
          </v-col>
          <v-col cols="12" md="2">
            <img v-bind:src="allPartners[4]" style="max-width:150px" height="70px"/>
          </v-col>
          <v-col cols="12" md="2">
            <img v-bind:src="allPartners[5]" style="max-width:150px" height="70px"/>
          </v-col>
        </v-row>
      </div>
    </div>
    <home-footer/>
  </v-app>
</template>

<script>
/* eslint-disable */
  import {SliderFrame, SliderSlides, SliderSlide} from 'vue-dynamic-slider';
  import HomeBar from './components/core/HomeBar.vue'
  import axios from 'axios'
  import HomeHeader from './HomeHeader.vue';
  import JQuery from 'jquery'
  window.$ = JQuery
  export default {
    name: 'Home',
    components: {
      HomeHeader,
      HomeBar,
      SliderSlide, SliderSlides, SliderFrame,
      HomeFooter: () => import('./components/core/HomeFooter'),
        HomeHeader,
    },
    data() {
      return {
        docs: [],
        vehicules: ['Fourgon 400 KG','Fourgon 1.5 TN', 'Camion 2.5 TN', 'Camion 3.5 TN', 'Camion 10 TN', 'Camion 20 TN'],
        newsfees: [
          {
            'title':'Title 1',
            'url':'https://andersonlogistique.com/media/k2/items/cache/1c0ae2205709722b62e843abc0471a55_XL.jpeg',
            'content':'Lancement d\'une nouvelle division Pharma-Logistique pour accompagner le secteur Pharmaceutique…'
          },
          {
            'title':'Title 2',
            'url':'https://andersonlogistique.com/media/k2/items/cache/fc34f61d23b74be53ee07d469bd32064_XL.jpg',
            'content':''
          },
          {
            'title':'Title 3',
            'url':'https://andersonlogistique.com/media/k2/items/cache/c9b002fe1bb0320831a8ae78670fdb6f_XL.jpg',
            'content':'La logistique est le nerf vital de l\'économie. Elle perm'
          },
          {
            'title':'Title 4',
            'url':'https://andersonlogistique.com/media/k2/items/cache/4965657af186b9092c7a96976ffe881c_XL.jpg',
            'content':"L’entreprise Anderson de logistique et de transport de marchandises s’implant"
          },
          {
            'title':'Title 5',
            'url':'https://andersonlogistique.com/media/k2/items/cache/9b2c4b44fb86522964124ed80d03c5e8_XL.jpg',
            'content':''
          },
          {
            'title':'Title 6',
            'url':'https://andersonlogistique.com/media/k2/items/cache/fa55c8bad0e242eb7986dc1135b50adb_XL.jpg',
            'content':''
          },
          {
            'title':'Title 7',
            'url':'https://andersonlogistique.com/media/k2/items/cache/fc1da7257992fc36032e11db3df7a664_XL.jpg',
            'content':'Anderson E-commerce logistics, partenaire Ecommerce de Adidas'
          }
        ],
        sliderImages: [
          '../../assets/Picture0.png',
          '../../assets/Picture1.png',
        ],
        activeIndex:0,
        canScrollNext:true,
        canScrollPrev:true,
        cslide: 0,
        selectedWilaya: {
          name:null,
          communues: []
        },
        selectedCommunue: null,
        trackingMessage: {
          show: false,
          status: null,
          message: null
        },
        loginMessage: {
          show: false,
          status: null,
          message: null
        },
        tracking_id: null,
        showPackageModal: false,
        messageWhenNoItems: 'There are not items',
        timelineItems: [
          // {
          //   from: new Date(2018, 7,1),
          //   title: 'Name',
          //   description:
          //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.'
          // },
        ],
        messagereseved: null,
        cemail: null,
        cname: null,
        telephone: null,
        text: null,
        emailexist: null,
        semail: null,
        spassword: null,
        name: null,
        address: null,
        phone: null,
        wilaya: null,
        wilayas: [],
        communue: null,
        email: null,
        auth_token: null,
        password: null,
        postCenters: null,
        selectedPost: 'ANB',
        cot:0,
        widthScreen:0,
        allNews: [
          {
            "image":require("../../assets/partners/6.png"),
            "title":"PHARMACIE CENTRAL D’ANNABA",
            "content":"TLM est le partenaire officiel de la pharmacie central d’annaba. après avoir rencontré la famille de la pharmacie centrale, nous sommes parvenus à un accord selon lequel TLM conservera toutes leurs transactions futures. Nous espérons que nous serons à la hauteur de la tâche. Merci pharmacie centrale.",
            "isContain":true
          },
          {
            "image":require("../../assets/partners/1.jpg"),
            "title":"EUROPA SPORT",
            "content":"TLM est le partenaire officiel de l'entreprise EUROPA SPORT. Nous remercions EUROPA SPORT pour leur confiance en espérant que nous serons toujours à la hauteur des attentes et donnerons tout ce que nous avons.",
            "isContain":true
          },
          {
            "image":require("../../assets/news2.png"),
            "title":"Nous sommes là pour vous",
            "content":"TLM acompagne les jeunes entrepreneurs et met a leurs disposition  une solution logistique complette.",
            "isContain":false
          },
          {
            "image":require("../../assets/news1.png"),
            "title":"Le meilleur dans le domaine pharmaceutique",
            "content":"La société de transport, grâce à sa longue expérience avec le secteur pharmaceutique, est actuellement leader dans ce domaine et met son expertise au service de toutes les usines et laboratoires.",
            "isContain":false
          },
        ],
        allPartners: [
          require('../../assets/partners/1.jpg'),
          require('../../assets/partners/2.jpg'),
          require('../../assets/partners/3.jpg'),
          require('../../assets/partners/4.jpg'),
          require('../../assets/partners/5.jpg'),
          require('../../assets/partners/6.png'),
          require('../../assets/partners/7.png'),
          require('../../assets/partners/8.jpg'),
          require('../../assets/partners/9.png'),
          require('../../assets/partners/10.jpg'),
          require('../../assets/partners/11.jpg'),
          require('../../assets/partners/12.jpg'),
          require('../../assets/partners/13.jpg'),
        ],
        showDriverModal:false,
        newDriver: {
          email:'',
          password:'',
        },
        newDriverErrorMessage:'',
        newDriverSuccessMessage:''
      }
    },
    mounted() {
      axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
      this.widthScreen = window.screen.availWidth;
      setTimeout(this.autoSlider,5000);
      this.getWilayas()
      // this.showSlides()
      // $(window).resize(function() {
      //   this.widthScreen = window.innerWidth;
      // });
    },
    methods: {
      loadFileName(index) {
        this.docs[index].file_name = this.$refs.file[index].files[0].name
      },
      addDocs() {
        this.docs.push({
          id:this.docs.length,
          name: '',
          file_name: '',
          content: ''
        })
        console.log(this.docs)
      },
      removeDoc(index) {
        this.docs.splice(index,1)
        console.log(this.docs)
      },
      addNewDriver() {
        axios.post('admin-add-driver', {
          post_info: this.newDriver,
          fees_exceptions: this.feesExceptions
        }).then(response => {
          if (response.data.status == 'success') {
            // this.showDriverModal = false
            this.feesExceptions = []
            this.newDriverErrorMessage = null
            this.newDriverSuccessMessage = 'Votre demande a été envoyé avec success'
            if (this.docs.length > 0) {
              let formData = new FormData()
              for (let i in this.docs) {
                formData.append('file'+i,this.$refs.file[i].files[0])
              }
              formData.append('files_info', JSON.stringify(this.docs))
              formData.append('post_info', JSON.stringify(this.newDriver))
              axios.post('driver-add-docs',formData,{
                headers: {
                      'Content-Type': 'multipart/form-data'
                },
              }).then(response => {
              })
            }
            this.newDriver= {
              email:null,
              password:null,
              wilaya:null,
              communue:null,
              phone:null,
              address:null,
              name: null,
              home_fees: null,
              code: null
            }
          } else {
            this.newDriverErrorMessage = response.data.error_message
            this.newDriverSuccessMessage = null
          }
        })
      },
      autoSlider() {
        this.toggleSlider('right')
        setTimeout(this.autoSlider,5000);
      },
      togglePartners(side) {
        if (side == 'right') {
          let element = this.allPartners[0];
          this.allPartners.splice(0,1);
          this.allPartners.push(element);
        }
        else if (side == 'left') {
          let element = this.allPartners[this.allPartners.length-1];
          this.allPartners.splice(this.allPartners.length-1,1);
          this.allPartners.unshift(element);
        }
      },
      toggleNews(side) {
        let allNewsContainers = document.getElementsByClassName('news-container-image-container');
        let fadeContainer = function() {
          for (let i = 0; i < allNewsContainers.length; i++) {
            allNewsContainers[i].classList.toggle('fade');
          }
        }
        fadeContainer();
        setTimeout(fadeContainer,500);
        if (side == 'right') {
          let element = this.allNews[0];
          this.allNews.splice(0,1);
          this.allNews.push(element);
        }
        else if (side == 'left') {
          let element = this.allNews[this.allNews.length-1];
          this.allNews.splice(this.allNews.length-1,1);
          this.allNews.unshift(element);
        }
      },
      toggleSlider(side) {
        if (side == 'right') {
          if (this.cot < this.sliderImages.length - 1) {
            $('.home-slider img').eq(this.cot).animate({'margin-left':'-'+window.screen.availWidth+'px'},1000);
            this.cot+=1;
          } else {
            this.cot = 0;
            for (let i = 0; i < this.sliderImages.length-1; i++) {
              $('.home-slider img').eq(i).animate({'margin-left':'0px'},500);
            }
          }
        }
        else if (side == 'left') {
          if (this.cot > 0) {
            this.cot--;
            $('.imgs img').eq(this.cot).animate({'margin-left':'0'},1000);
          } else {
            this.cot=this.sliderImages.length - 1;
            for (let i = 0; i < this.sliderImages.length-1; i++) {
              $('.home-slider img').eq(i).animate({'margin-left':'-'+window.screen.availWidth+'px'},500);
            }
          }
        }
      },
      getPostCenters() {
        axios.post('get-post-centers').then(response => {
          if (response.data.status == 'success') {
            this.postCenters = response.data.data
          }
        })
      },
      getWilayas() {
      axios.post('get-wilayas').then(response => {
          if (response.data.status == 'success') {
            this.wilayas = response.data.data
          }
        })
      },
      trackPackage() {
        axios.post('track-package', {
          tracking_id: this.tracking_id
        }).then(response => {
          if (response.data.status == 'success') {
            this.timelineItems = []
            response.data.data.forEach(element => {
              this.timelineItems.push({
                from: new Date(element.creation_date),
                title: element.message,
                showDayAndMonth: true,
                // description:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.'
                })
            });
            this.showPackageModal = true
          }
          else {
            this.trackingMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
          }
        })
      },
      handleModalHide(params) {
        this.showPackageModal = false
      },
      hideModal(params) {
        this.showPackageModal = false
      },
      centerLogin() {
        axios.post('center-login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.status =='success') {
            this.auth_token = response.data.data.auth_token
            localStorage.setItem('auth_token',response.data.data.auth_token)
            localStorage.setItem('user_type',response.data.data.type)
            this.$router.push({name:'CenterDashboard'})
            } else {
              console.log(response.data.error_message)
              this.loginMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
            }
        })
      },
      depotLogin() {
        axios.post('depot-login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.status =='success') {
            this.auth_token = response.data.data.auth_token
            localStorage.setItem('auth_token',response.data.data.auth_token)
            localStorage.setItem('user_type',response.data.data.type)
            this.$router.push({name:'DepotDashboard'})
            } else {
              console.log(response.data.error_message)
              this.loginMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
            }
        })
      },
      contectus(){
        axios.post('contectus',{
          cemail: this.cemail,
          cname: this.cname,
          telephone: this.telephone,
          text: this.text,
        }).then(response => {
          this.messagereseved = response.data.status
          this.cemail=null
          this.cname=null
          this.telephone=null
          this.text=null
        })
      },
      addprovider(type) {
        axios.post('addprovider',{
          semail: this.semail,
          spassword: this.spassword,
          name: this.name,
          phone: this.phone,
          address: this.address,
          communue: this.communue,
          wilaya: this.wilaya,
          post: this.selectedPost,
          type:type
        }).then(response => {
          this.emailexist = response.data.status
          this.semail=null
          this.spassword=null
          this.name=null
          this.phone=null
          this.address=null
          this.selectedWilaya= {
            name:null,
            communues: []
          }
          this.selectedCommunue=null
          this.selectedPost = null
        })

      },
      login() {
        axios.post('login', {
          email: this.email,
          password: this.password
        }).then(response => {
          if (response.data.status =='success') {
            this.auth_token = response.data.data.auth_token
            localStorage.setItem('auth_token',response.data.data.auth_token)
            localStorage.setItem('user_type',response.data.data.type)
            this.$router.push({name:'Dashboard'})
          } else {
              console.log(response.data.error_message)
              this.loginMessage = {
                status: response.data.status,
                message: response.data.error_message,
                show: true
              }
          }
        })
      },
      holdOnDiv(stand) {
        if (this.colClass == '') this.colClass = 'pa-4 success text-no-wrap rounded-xl'
        else if (this.colClass == 'pa-4 success text-no-wrap rounded-xl') this.colClass = ''
        if (stand == 1) {
          this.stand1 = !this.stand1
          this.stand2 = false
          this.stand3 = false
        } else if (stand == 2) {
          this.stand1 = false
          this.stand2 = !this.stand2
          this.stand3 = false
        } else {
          this.stand1 = false
          this.stand2 = false
          this.stand3 = !this.stand3
        }
      }
    }
  }
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat:300');
/* html, body {
    overflow-x: hidden;
} */

.stick-button{
  display:none;
}

body {
  font-family: 'Montserrat';font-size: 16px;
}

body h1{
  font-family: 'Montserrat';
}

body h1{
  font-family: 'Montserrat';
}

body h2, h3{
  font-family: 'Montserrat';
}

body p{
  font-family: 'Montserrat';
}

.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
}
.v-application--wrap {
    min-height: 100vh;
    justify-content: center;
}
.container {
  text-align: center;
}
#home {
  background: white;
  background-size: cover;
  width: 100%;
}
input {
    border: 2px;
    border-color: black;
    background: white;
}
.mySlides {display: none;}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
.v-application--wrap {
  -webkit-box-pack:unset;
  justify-content: unset;
}
.circle {
    width:20%;display:flex;margin-bottom: 50px;text-align:center;justify-content:center;
    content: "";
    -webkit-box-shadow: inset 0 0 5px 2px #00a4de;
    box-shadow: inset 0 0 5px 2px #00a4de;
    border-radius: 50%;
    padding: 20%;
    top: 16%;
    bottom: 16%;
    left: 16%;
    right: 16%;
    background-color: rgba(216,216,216,.05);
}

.inside-circle {
    text-align:center;justify-content:center;
    width:50%;
    -webkit-box-shadow: inset 0 0 5px 2px red;
    box-shadow: inset 0 0 5px 2px red;
    border-radius: 50%;
    background-color: red;
}
.one-slide {
  padding:20px;
  justify-content:center;
  text-align:center;
  width:23%;
  display:inline-block
}
.one-slide:hover {
  transform: scale(1.1);
}

.home-slider {
  width:100%;
  height: 677px;
  /* overflow: hidden;
  position: relative; */
}

.slider-button {
  display: inline-block;
  font-size:20px;
  width:60px;
  height:60px;
  justify-content:center;
  text-align:center;
  background-size: contain;
  /* box-shadow: 0 0 5px 5px grey;
  border-radius: 50%; */
}

.slider-button-left {
  background-image: url('../../assets/scroll_left.png');
  float:left;
}
.slider-button-right {
  background-image: url('../../assets/scroll_right.png');
  float:right;
}

.home-slider img {
  height: 677px;
  float: left;
}
.cursor-pointer {
  cursor : pointer
}
.home-tiles {
  width:100%;
  display:flex;
  margin-bottom: 50px;

  justify-content: center;
}
.read-container:first-child {
  margin-left:100px;
  background-image: url('../../assets/gpb1.png');
}

.read-container:nth-child(2) {
  background-image: url('../../assets/gpb2.png');
}

.read-container:nth-child(3) {
  background-image: url('../../assets/gpb3.png');
}

.read-container {
  height:240px;
  width:25%;
  margin:20px;
  display:flex;
  background-size: cover;
  /* box-shadow: inset 0 0 0 1000px rgba(11,41,99,0.8); */
  padding:20px;
  border-radius:20px;
  text-transform:none;
  font-size:18px;
  font-weight:400;
  line-height: 22px;
  text-decoration:none !important;
  color:white !important;
  text-align: center;
  justify-content: center;
}

.read-container:hover:first-child {
  background-image: url('../../assets/gp1.png');
}

.read-container:hover:nth-child(2) {
  background-image: url('../../assets/gp2.png');
}

.read-container:hover:nth-child(3) {
  background-image: url('../../assets/gp3.png');
}

.read-container:hover {
  box-shadow: inset 0 0 0 1000px rgba(254,225,9,0.8);
  transition-delay:0.2s;
}
.read-container:hover div{
  transform: scale(1.1);
  transition-delay:0.2s;
}
.home-def {
  margin-left:180px;
  width:100%;
}

.news-container {
  height:550px;
  width:34%;
  margin:20px;
  background-color: white;
  /* background-color: #eeeeee; */
  border-radius:10px;
  text-transform:none;
  font-size:14px;
  font-weight:400;
  line-height: 25px;
  text-decoration:none !important;
  color:#333333 !important;
}

.news-container:hover {
  transform: scale(1.05);
  transition-delay:0.2s;
}

.news-container:hover h2{
  color:#ffe209 !important;
}

.news-container:hover .v-icon{
  color:#ffe209 !important;
}

.news-container-image-first {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.news-container-image-second {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.news-container-image {
  width:100%;
  height:250px;
  background-size: cover;
  background-position: center;
}

.news-container-image-container {
  width:100%;
  justify-content: center;
  text-align: center;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #eeeeee;
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3 ease-in-out;
}

.news-container-image-container.fade {
  opacity: 0;
}

.news-container-content {
  width:100%;
  height:230px;
  padding:15px 15px;
  overflow-x:hidden;
}

.news-container-content::-webkit-scrollbar {
  width:0px;
}

.news-container-content h3{
  font-size:24px;
  line-height: 2;
  font-weight: 700;
}

.news-container-content p{
  font-size:17px;
  line-height: 2;
  font-weight: 500;
}

.mission-container {
  height:400px;
  width:18%;
  margin:20px;
  background-color: white;
  border-radius:10px;
  text-transform:none;
  font-size:14px;
  font-weight:400;
  line-height: 25px;
  text-decoration:none !important;
  color:#333333 !important;
}

.mission-container:hover {
  transform: scale(1.1);
  transition-delay:0.2s;
}

.mission-container:hover h2{
  color:#ffe209 !important;
}

.mission-container:hover .v-icon{
  color:#ffe209 !important;
}
.mission-container-image-container {
  width:100%;
  padding:10px;
  justify-content: center;
  text-align: center;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #eeeeee;
}
.mission-container-image {
  width:100%;
  height:150px;
  background-size: contain;
  background-position: center;
}

.mission-container-image-first {
  background-image: url('../../assets/cargo_truck2.png');
}
.mission-container-image-second {
  background-image: url('../../assets/pack1.png');
}
.mission-container-image-third {
  background-image: url('../../assets/pack2.png');
}
.mission-container-image-fourth {
  background-image: url('../../assets/trucks.png');
}

.service-container {
  height:512px;
  width:25%;
  margin:20px;
  display:flex;
  padding:20px;
  border-radius:10px;
  text-transform:none;
  font-size:14px;
  font-weight:400;
  line-height: 25px;
  text-decoration:none !important;
  color:black !important;
  text-align: center;
  justify-content: center;
}

.service-container img:nth-child(2) {
  display: none;
}

.service-container:hover img:nth-child(2) {
  display: inline-block;
}
.service-container:hover img:nth-child(1) {
  display: none;
}

.service-container-middle {
  background-color:#0b2963;
  height:512px;
  width:25%;
  margin:20px;
  display:flex;
  padding:20px;
  border-radius:10px;
  text-transform:none;
  font-size:14px;
  font-weight:400;
  line-height: 25px;
  text-decoration:none !important;
  color:black !important;
  text-align: center;
  justify-content: center;
}

.service-container-middle img:nth-child(1) {
  display: none;
}

.service-container-middle:hover img:nth-child(2) {
  display: none;
}

.service-container-middle:hover img:nth-child(1) {
  display: inline-block;
}

.service-container:hover {
  background-color:#0b2963;
}

.service-container:hover h2{
  color:#ffe209 !important;
}

.service-container:hover .v-icon{
  color:#ffe209 !important;
}

.service-container:hover p{
  color:white !important;
}

.service-container-middle:hover {
  background-color:#ffe209;
}

.service-container-middle:hover h2{
  color:#0b2963 !important;
}

.service-container-middle:hover .v-icon{
  color:#0b2963 !important;
}

.service-container-middle:hover p{
  color:black !important;
}

.mission-container-content {
  width:100%;
  height:173px;
  padding:15px 30px;
  overflow-x: hidden;
}

.mission-container-content::-webkit-scrollbar {
  width: 0px;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.arrow-left {
  margin-right:5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow-right {
  margin-left:5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.menu-item-bar {
  background-color: #ffe209;width: 100%;display: none;height: 5px;
}
.mission-container:hover .menu-item-bar {
    display: block;
}

.news-container:hover .menu-item-bar {
    display: block;
}

.mission-container:hover .mission-container-image-container {
  background-color: #ffe209;
}

.tlm-numbers-container {
  text-align: center;
  justify-content: center;
  padding:20px 200px;
  width:100%;
  height:330px;
  box-shadow: inset 0 0 0 1000px rgba(237,237,237,0.2);
  background-image: url('../../assets/background-panorama.png');
  background-size: cover;
}

.tlm-numbers-container-title-title {
  margin-top:10px;
  width:100%;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:700');
.tlm-numbers-container-title-title h1 {
  color:#0b2963;
  font-size: 54px;
  font-weight: 700;
}
.tlm-numbers-container-title-title h1{
  color:#0b2963;
  font-weight: 700;
}
.tlm-numbers-container-title-title div h1{
  color:#0b2963;
  font-weight: 700;
  font-size:120px;
}
.tlm-numbers-container-title-title h2{
  color:#0b2963;
  font-weight: 700;
}


.tlm-numbers-container-title:hover h1{
  color:#ffe209;
  cursor:pointer
}

.tlm-numbers-container-title:hover h2{
  color:#ffe209;
  cursor:pointer
}

.our-partners {
  width:100%;
  background-color:#f6f6f6;
  height:240px;
  text-align: center;
  justify-content: center;
  padding:20px 100px;
}
.tlm-spacer {
  display: none;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:800');
.review-title {
  color:#0b2963;font-weight:900;margin-top:160px;font-size:50px
}
@import url('https://fonts.googleapis.com/css?family=Montserrat:500');
.review-content {
  color:black;margin-top:40px;font-size:20px;font-weight: 500;
}

.home-slider-title {
  position:absolute;margin:150px 0px 0px 250px;
}

.home-slider-title h1 {
  padding:1px 10px;color:#0b2963;text-transform:uppercase;font-weight:700;font-size:50px
}

.home-tiles-1 {
  margin-top:-120px
}
.home-tiles-2 {
  margin-left:25px;z-index: 2
}

.slider-button-1 {
  margin:-450px 180px;
}

.driver-container {
  height:500px;width:50%;background-color:transparent;display:inline-block
}

.driver-container-main {
  height:500px;width:506px;background-color:transparent;margin:100px 0px 0px 100px;
}

.driver-container-content {
  height:476px;width:506px;background-color:#ffe209;border-radius:20px;padding:50px 50px;text-align:center
}

.driver-container-content h1 {
  color:#0b2963;margin-bottom:20px;font-weight:900;font-size:45px
}

.driver-container-content p {
  color:#0b2963;font-size:18px;font-weight:500
}

.driver-container-button {
  display:flex;flex-wrap:wrap;justify-content:center;width:100%;margin-top:-35px
}

.driver-container-button div {
  font-size:30px;font-weight:700;padding:15px 0px 0px 0px;color:white;text-align:center;width:313px;background-color:#0b2963;border-radius:50px;cursor:pointer
}

@media (min-width:1600px) {
  .home-slider {
    height: 800px;
  }
  .home-slider img {
    height: 800px !important;
  }
  .read-container {
    height: 280px;
    padding:40px;
  }
  .home-def {
    margin-left:240px;
  }
  .tlm-spacer {
    display:inline-block;
  }
}

@media (max-width:800px) {
  html, body {
      overflow-x: hidden;
  }
  .home-slider {
    height: 250px;
  }
  .home-slider img {
    height: 250px !important;
  }
  .home-slider-title {
    position:absolute;margin:40px 0px 0px 0px;
  }
  .home-slider-title h1 {
    padding:1px 10px;color:#0b2963;text-transform:uppercase;font-weight:700;font-size:20px
  }
  .home-tiles {
    display: block;
  }
  .read-container:first-child {
    margin-left:40px
  }
  .read-container {
    margin-left:40px;
    min-width:300px;
    display: block !important;
  }
  .home-tiles-1 {
    margin-top:-70px
  }
  .home-def {
    padding:0px 5%;
    margin:0px 0px;
  }
  .home-tiles-2 {
    margin-left:0px;
    padding:0px 20px;
  }

  .mission-container-content {
    background-color: rgb(250, 250, 250);
  }  

  .menu-item-bar {
    display: block;
  }
  .home-tiles-3 {
    padding:0px 0px;
  }  
  .service-container-middle {
    width:100%;
    border-radius: 0px;
    margin:20px 0px;
    height: 500px;
  }

  .slider-button-1 {
    margin:-130px 0px;
  }
  .home-tiles-4 {
    margin:0px !important;
    padding:0px 40px;
  }


  .news-container-content {
    height: 320px !important;
  }
  .tlm-numbers-container {
    text-align: center;
    justify-content: center;
    padding:20px 0px;
    width:100%;
    height:800px;
    box-shadow: inset 0 0 0 1000px rgba(237,237,237,0.2);
    background-image: none;
  }

  .tlm-numbers-container-title-title {
    margin-top:10px;
    width:100%;
  }
  .review-man {
    width:100%
  }
  
  .driver-container {
    height:300px;background-color:transparent;display:inline-block;justify-content: center;
  }

  .driver-container-main {
    height:300px;width:300px;background-color:transparent;margin:0px 0px 0px -25%;
  }

  .driver-container-content {
    height:300px;width:300px;background-color:#ffe209;border-radius:20px;padding:20px 20px;text-align:center
  }

  .driver-container-content h1 {
    color:#0b2963;margin-bottom:10px;font-weight:900;font-size:24px
  }

  .driver-container-content p {
    color:#0b2963;font-size:14px;font-weight:500
  }

  .driver-container-button {
    display:flex;flex-wrap:wrap;justify-content:center;width:100%;margin-top:-35px
  }

  .driver-container-button div {
    font-size:16px;font-weight:700;padding:15px 0px 0px 0px;color:white;text-align:center;width:150px;background-color:#0b2963;border-radius:50px;cursor:pointer
  }

  .stick-button{
    display: block;
    width:100%;
    text-align: right;
    position: fixed;
    float:right;
    height: 10%;
    bottom: 0;
    left: 0;
    z-index:10;
  }
}
</style>